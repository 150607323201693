import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import SidebarCustom from '../scenes/global/SidebarCustom';
import Dashboard from '../scenes/dashboard';
import DonHang from '../scenes/DonHang/DonHang';
import KhoHang from '../scenes/KhoHang/KhoHang';
import LayoutManager from '../pagesManager/LayoutManager';
import LoginManager from '../pagesManager/LoginManager';
import TaoDonHang from '../scenes/DonHang/TaoDonHang';

const Manager = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <CssBaseline />
      <Box sx={{ width: '100%' }}>
        <Routes>
          <Route path="*" element={<LoginManager />} />
        </Routes>
      </Box>

      {/* <LayoutManager />
      <Box id="box-menu-manager" sx={{ display: 'flex', flexGrow: 1 }}>
        <SidebarCustom />
        <Box component="main" sx={{ flexGrow: 1, p: 3, overflowY: 'auto' }}>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/khohang" element={<KhoHang />} />
            <Route path="/donhang" element={<DonHang />} />
            <Route path="/donhang/tao-don-hang" element={<TaoDonHang />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </Box>
      </Box> */}
    </Box>
  );
};

// const App = () => (
//   <AuthProvider>
//     <Manager />
//   </AuthProvider>
// );

export default Manager;