import React, { useState } from "react";

const PageTest = () => {
    const [count, setCount] = useState(0);
    return (
        <div>
            <h1>This is page test: {count}</h1>
            <input/>
            <button onClick={() => setCount(count + 1)}>
            Click me
            </button>
        </div>
        )
  };
  
  export default PageTest;



  