import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper } from '@mui/material';

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <Box key={interval} sx={{ textAlign: 'center', mx: 1 }}>
        <Paper elevation={3} sx={{ padding: 2, minWidth: 60 }}>
          <Typography variant="h4" component="div">
            {timeLeft[interval]}
          </Typography>
          <Typography variant="caption" component="div">
            {interval}
          </Typography>
        </Paper>
      </Box>
    );
  });

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
      {timerComponents.length ? timerComponents : <Typography variant="h5">Time's up!</Typography>}
    </Box>
  );
};

export default Countdown;