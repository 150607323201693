import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Import BrowserRouter
import './App.css';
import Home from "./pages/Home";
import Manager from "./pages/Manager";
import PageTest from "./pages/PageTest";
import PopupTest from "./pages/PopupTest";
import NoPage from "./pages/NoPage";
import { Outlet } from 'react-router-dom';
// import { useState, useEffect } from 'react';
// import { supabase } from './client';


// const handleUpdate = (payload) => {
//   console.log('Change received!', payload)
// }

// Listen to update
// supabase
//   .channel('Products')
//   .on('products_changes', { event: 'UPDATE', schema: 'public', table: 'Products' }, handleUpdate)
//   .subscribe()



function App() {
  // const [Products, setProducts] = useState([]);

  // useEffect(() => {
  //   getProducts();
  // }, []);

  // async function getProducts() {
  //   const { data } = await supabase.from("Products").select();
  //   setProducts(data);
  // }
  return (
    <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="/*" element={<Manager />} />
          <Route path="pagetest" element={<PageTest />} />
          <Route path="popuptest" element={<PopupTest />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
    </BrowserRouter>
    
  );
}

export default App;
