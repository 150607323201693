import { createClient } from "@supabase/supabase-js";

//export const supabase = createClient(process.env.SUPABASE_URL, process.env.SUPABASE_ANON_KEY);
export const supabase = createClient('https://pbmuhirlmnfjczmzncbt.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBibXVoaXJsbW5mamN6bXpuY2J0Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODgyODEyMDAsImV4cCI6MjAwMzg1NzIwMH0.5jccuLBAzlQu2Z19FiagNe1rda6jL4lqRQ9Vm5S5SPM');
// export const supabase = createClient('https://pbmuhirlmnfjczmzncbt.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBibXVoaXJsbW5mamN6bXpuY2J0Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY4ODI4MTIwMCwiZXhwIjoyMDAzODU3MjAwfQ.37-Z8ZqYpXUTOZg7CZ-4OzwzPxVQRgFTteWOdqwnv6U');

export const checkLoginManager = async (id_login, password) => {
    const { data, error } = await supabase
      .from('Accounts')
      .select('*')
      .eq('id_login', id_login)
      .eq('password', password)
      .single();
    return { data, error };
  };