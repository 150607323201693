import React from 'react';
import { Box, Typography, Container, Button, Paper } from '@mui/material';
import Countdown from './Countdown';

const Home = () => {
  return (
    <Container maxWidth="md" sx={{ textAlign: 'center', mt: 5 }}>
      <Paper elevation={3} sx={{ padding: 4, backgroundColor: '#f5f5f5' }}>
        <Typography variant="h2" component="h1" gutterBottom sx={{ color: '#3f51b5' }}>
          Coming Soon
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
          <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#f50057' }}>
            Countdown to 30/10/2024:
          </Typography>
          <Countdown targetDate="2024-10-30T00:00:00" />
        </Box>
        <Typography variant="body1" component="p" sx={{ mt: 2, color: '#4caf50' }}>
          Vui lòng liên hệ 0934 92 92 88 để biết thêm chi tiết
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => window.location.href = 'https://www.facebook.com/hipchue'}
          sx={{ mt: 2, mr: 2, backgroundColor: '#3f51b5' }}
        >
          Liên Hệ Fanpage Facebook
        </Button>
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={() => window.location.href = 'mailto:contact@example.com'}
          sx={{ mt: 2, backgroundColor: '#f50057' }}
        >
          Gửi Email Liên Hệ
        </Button>
      </Paper>
      
      <Box sx={{ position: 'fixed', bottom: 0, left: '50%', transform: 'translateX(-50%)', textAlign: 'center', pb: 2, display: 'flex', justifyContent: 'center', width: '100%' }}>
        <MyButton />
      </Box>
    </Container>
    
  );
};

export default Home;

function MyButton() {
  return (
    <Typography 
      variant="body2" 
      sx={{ mt: 4, cursor: 'pointer', color: '#9e9e9e' }} 
      onClick={() => window.location.href = '/manager'}
    >
      Copyright Hi PC ® (thương hiệu đã đăng ký bản quyền)
    </Typography>
   
  );
}